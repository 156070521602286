import * as React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

const Container = styled.div`
    width: min(90%, 1100px);
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (min-width: 800px) {
       flex-direction: initial;
    }
`

const Image = styled(GatsbyImage)`
    border-radius: 50% 45% 35% 25% / 70% 45% 30% 20%;
    flex-basis: 40%;

    img {
        border-radius: 50% 45% 35% 25% / 70% 45% 30% 20%;
    }
`

const TextBox = styled.div`
    padding: var(--space-4) min(5%, var(--space-5)) var(--space-5) min(5%, var(--space-5));
    background-color: var(--secondary-200);
    color: var(--secondary-800);
    box-shadow: var(--shadow-elevation-high);
    margin-top: calc(var(--space-3) * -3);
    z-index: 999;
    flex-basis: 60%;
    height: max-content;
    width: min(90%, 450px);

    @media only screen and (min-width: 800px) {
       margin-top: 0;
       margin-left: calc(var(--space-3) * -3);
       width: 100%;
    }
`

function ContactCTA(props) {
    return (
        <Container>
            <Image image={props.image} alt={props.alt} />
            <TextBox>
                {props.children}
            </TextBox>
        </Container>
    )
}

export default ContactCTA