import * as React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Container = styled.div`
    flex-basis: 375px;
    flex-grow: 1;

    h3 {
        font-size: var(--type-m);
    }
`

const Icon = styled.div`
    color: var(--primary-600);
    padding: var(--space-2);
    border-radius: 50%;
    border: 2px solid var(--primary-600);
    width: max-content;
    display: flex;
    justify-content: center;
    align-items: center;
    
    svg {
        width: 20px;
        height: 20px;
    }
`

function IconTile(props) {
    return (
        <Container>
            <Icon>
                <FontAwesomeIcon icon={props.icon} />
            </Icon>
            {props.children}
        </Container>
    )
}

export default IconTile