import * as React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

const Container = styled.div`
    width: min(90%, 900px);
    margin: auto;
`

const Box = styled.div`
    padding: var(--space-5) 5%;
    background-color: var(--secondary-800);
    box-shadow: var(--shadow-elevation-medium);
    margin: var(--space-5) 0;
`

const Quote = styled.div`
    margin-bottom: var(--space-4);

    p {
        padding: 0 var(--space-5);
        color: var(--secondary-100);
    }

    :before {
        content: open-quote;
        top: 20px;
        justify-content: flex-start;
    }

    :after {
        content: close-quote;
        top: 20px;
        justify-content: flex-end;
    }

    :before, :after {
        font-size: var(--type-xxl);
        position: relative;
        line-height: 0.5;
        display: flex;
    }
`

const Author = styled.div`
    display: flex;
    align-items: center;
`

const Image = styled.div`
    border-radius: 100%;
    background-color: white;
    width: var(--fixed-space-7);
    height: var(--fixed-space-7);
    margin: 0 var(--space-3) 0 var(--space-5);
    padding: var(--space-1);
    flex-shrink: 0;

    img {
        border-radius: 100%;
    }
`

const Name = styled.p`
    text-transform: uppercase;
    font-weight: 700;
    margin: 0;
    color: var(--primary-600);
`

const Business = styled.p`
    font-weight: 200;
    margin: 0;
    color: var(--secondary-200);
`

function Testimonial(props) {
    return (
        <Container>
            <h2 style={{textAlign: 'center'}}>Don't just take my word for&nbsp;it...</h2>
            <Box>
                <Quote>{props.children}</Quote>
                <Author>
                    <Image>
                        <GatsbyImage image={props.image} alt="Brand icon" />
                    </Image>
                    <div>
                        <Name>{props.name}</Name>
                        <Business>{props.business}</Business>
                    </div>
                </Author>
            </Box>
        </Container>
    )
}

export default Testimonial